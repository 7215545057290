// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archives-js": () => import("./../src/pages/archives.js" /* webpackChunkName: "component---src-pages-archives-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-brands-js": () => import("./../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-recalls-js": () => import("./../src/pages/recalls.js" /* webpackChunkName: "component---src-pages-recalls-js" */),
  "component---src-pages-repairs-js": () => import("./../src/pages/repairs.js" /* webpackChunkName: "component---src-pages-repairs-js" */),
  "component---src-templates-articles-js": () => import("./../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-brands-js": () => import("./../src/templates/brands.js" /* webpackChunkName: "component---src-templates-brands-js" */),
  "component---src-templates-categories-js": () => import("./../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-pages-js": () => import("./../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-products-js": () => import("./../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-recall-years-js": () => import("./../src/templates/recallYears.js" /* webpackChunkName: "component---src-templates-recall-years-js" */),
  "component---src-templates-recalls-js": () => import("./../src/templates/recalls.js" /* webpackChunkName: "component---src-templates-recalls-js" */),
  "component---src-templates-repairs-js": () => import("./../src/templates/repairs.js" /* webpackChunkName: "component---src-templates-repairs-js" */)
}

